<template>
  <div class="font-small-3">
    <b-table
      responsive
      show-empty
      small
      bordered
      empty-text="No matching records found"
      :per-page="perPage"
      :filter="filter"
      :filter-included-fields="filterOn"
      :items="groupByData.map(item => ({ ...item, _showDetails: true }))"
      :fields="fields1"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      :current-page="currentPage"
      :row-details-provider="rowDetailsProvider"
      style="text-align: center; font-size: small;"
      class="main-table"
      header-class="main-table-header"
      @filtered="onFiltered"
    >
      <template #row-details="row">
        <b-table
          :items="row.item.games"
          :fields="fields2"
          responsive
          small
          class="mb-1"
        >
          <template #cell(gameId)="data">
            <div style="min-width: 8rem; color: white">
              {{ data.value }}
            </div>
          </template>
          <template #cell(seq)="data">
            <div style="text-align: center; min-width: 2rem; color: white">
              {{ data.item.seq }}
            </div>
          </template>
          <template #cell(gameDate)="data">
            <div style="text-align: center; min-width: 7rem; color: white">
              {{ data.item.gameDate.slice(2) }}
            </div>
          </template>
          <template #cell(sports)="data">
            <div style="text-align: center; min-width: 4rem; color: white">
              {{ data.item.sports }}
            </div>
          </template>
          <template #cell(leagueName)="data">
            <div
              v-b-tooltip.hover.bottom.v-danger="data.item.leagueName"
              class="text-truncate"
              style="text-align: left; max-width: 14rem; color: white"
            >
              {{ data.item.leagueName }}
            </div>
          </template>
          <!-- 필요한 추가 필드 템플릿을 여기에 추가 -->
          <template #cell(homeName)="data">
            <div
              v-b-tooltip.hover.bottom.v-danger="data.item.homeName"
              :class="{ 'highlight': data.item.homeAway === 'Home' || data.item.homeAway === 'Over' }"
              size="sm"
              class="text-truncate"
              style="text-align: right; float: right; min-width: 14rem; color: white; padding-right: 0.5rem;"
            >
              {{ data.item.homeName }}
            </div>
          </template>
          <template #cell(oddsHome)="data">
            <div
              size="sm"
              style="min-width: 2rem; color: white;"
            >
              {{ formatNumber(data.item.oddsHome) }}
            </div>
          </template>
          <template #cell(handicap)="data">
            <div
              :class="{ 'highlight': data.item.homeAway === 'Draw'}"
              style="text-align: center; min-width: 2rem; color: white;"
            >
              <div v-if="data.item.handicap === null">
                VS
              </div>
              <div v-else>
                {{ data.item.handicap }}
              </div>
            </div>
          </template>
          <template #cell(oddsAway)="data">
            <div
              size="sm"
              style="min-width: 2rem; color: white;"
            >
              {{ formatNumber(data.item.oddsAway) }}
            </div>
          </template>
          <template #cell(awayName)="data">
            <div
              v-b-tooltip.hover.bottom.v-danger="data.item.awayName"
              :class="{ 'highlight': data.item.homeAway === 'Away' || data.item.homeAway === 'Under' }"
              size="sm"
              class="text-truncate"
              style="text-align: left; float:left; min-width: 14rem; color: white; padding-left: 0.5rem"
            >
              {{ data.item.awayName }}
            </div>
          </template>
          <template #cell(oddsType)="data">
            <div style="text-align: center; min-width: 4rem; color: white;">
              {{ oddsTypeText(data.item.oddsType) }}
            </div>
          </template>
          <template #cell(homeTeamResult)="data">
            <div style="text-align: center; min-width: 2rem; color: white;">
              <div v-if="!data.item.homeTeamResult">
                -
              </div>
              <div v-else>
                {{ data.item.homeTeamResult }}
              </div>
            </div>
          </template>
          <template #cell(awayTeamResult)="data">
            <div style="text-align: center; min-width: 2rem; color: white;">
              <div v-if="!data.item.awayTeamResult">
                -
              </div>
              <div v-else>
                {{ data.item.awayTeamResult }}
              </div>
            </div>
          </template>
          <template #cell(status)="data">
            <div style="text-align: center; min-width: 4rem; color: white;">
              {{ statusTypeText(data.item.status) }}
            </div>
          </template>
          <template #cell(betResult)="data">
            <div style="text-align: center;">
              <div
                v-if="data.item.betResult === 'WIN' && data.item.oddsType !== 'Bonus'"
                style="color: limegreen; min-width: 5rem"
              >
                적중
              </div>
              <div
                v-else-if="data.item.oddsType === 'Bonus'"
                style="color: limegreen; min-width: 5rem"
              >
                보너스
              </div>
              <div
                v-else-if="data.item.betResult === 'LOSS'"
                style="color: red; min-width: 5rem"
              >
                미적중
              </div>
              <div
                v-else-if="data.item.betResult === 'TIE'"
                style="color: yellow; min-width: 5rem"
              >
                적중특례
              </div>
              <div
                v-else-if="!data.item.betResult"
                style="color: dodgerblue; min-width: 5rem"
              >
                결과대기
              </div>
              <div
                v-else-if="data.item.betResult === 'CUSTOM TIE'"
                style="color: yellow; font-size: x-small; min-width: 5rem"
              >
                적중특례
              </div>
              <div
                v-else-if="data.item.betResult === 'CUSTOM CANCELED'"
                style="color: yellow; font-size: x-small; min-width: 5rem"
              >
                배팅취소
              </div>
              <div
                v-else-if="data.item.betResult === 'USER CANCELED'"
                style="color: yellow; font-size: x-small; min-width: 5rem"
              >
                배팅취소
              </div>
            </div>
          </template>
        </b-table>
        <div
          class="row"
          style="color: white; background-color: rgb(21 28 56 / 69%); height: 2rem; margin: 1rem;"
        >
          <div
            class="col"
            style="align-self: center"
          >
            배팅번호 : {{ record.groupId }}
          </div>
          <div
            class="col"
            style="align-self: center"
          >
            배팅시간 : {{ row.item.games[0].createdAt | formatDate('YYYY-MM-DD HH:mm') }}
          </div>
          <div
            class="col"
            style="align-self: center; color:rgb(196,186,138)"
          >
            베팅금액 : {{ Number(row.item.games[0].betCash).toLocaleString() }}
          </div>
          <div
            class="col"
            style="align-self: center; color:rgb(196,186,138)"
          >
            총배당 : {{ Number(row.item.totalOdds).toLocaleString() }}
          </div>
          <div
            class="col"
            style="align-self: center; color:rgb(196,186,138)"
          >
            적중금 : {{ Number(row.item.totalHitCash).toLocaleString() }}
          </div>
        </div>
        <div
          v-if="row.item.confirmed === 'YET'"
          class="text-left mb-1 ml-2"
        >
          <b-button
            class="mr-1"
            size="sm"
            style="font-size: small; border: 1px solid yellow; background-color: transparent !important; color: yellow !important;"
            @click="submit('updateGameCustomCancel', record.groupId)"
          >
            배팅 취소
          </b-button>
        </div>
        <div
          v-if="row.item.confirmed === 'USER CANCELED'"
          class="text-left mb-1 ml-2"
          style="font-size: small; color: yellow !important;"
        >
          배팅취소 완료
        </div>
      </template>
    </b-table>
  </div>
</template>

<script>
import {
  BCard,
  VBToggle,
  BTable,
  BRow,
  BCol,
  BFormSelect,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BButton,
  VBTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { createNamespacedHelpers } from 'vuex'
import { FETCH_CLIENT_USER_BET_HISTORY_DETAIL } from '@/store/result/action'
import { UPDATE_CLIENT_USER_BET_CANCEL } from '@/store/result/mutation'
import * as _ from 'lodash'
import Swal from 'sweetalert2'
import * as moment from 'moment-timezone'

const authStore = createNamespacedHelpers('authStore')
const resultStore = createNamespacedHelpers('resultStore')

export default {
  name: 'RefundHitResultDetails',
  components: {
    BTable,
    BRow,
    BCol,
    BButton,
    VBTooltip,
  },
  directives: {
    'b-toggle': VBToggle,
    Ripple,
    'b-tooltip': VBTooltip,
  },
  props: {
    record: {
      type: Object,
      required: true,
    },
    // homeAway: {
    //   type: String,
    //   required: true,
    // },
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      userIp: localStorage.getItem('ip'),
      searchText: '',
      option3: [
        { value: 'Match Winner', text: '승무패' },
        { value: 'Home/Away', text: '승무패' },
        { value: 'Asian Handicap', text: '핸디캡' },
        { value: 'Asian Handicap (Games)', text: '핸디캡' },
        { value: 'Maps Handicap', text: '핸디캡' },
        { value: 'Goals Over/Under', text: '오버언더' },
        { value: 'Over/Under', text: '오버언더' },
        { value: 'Over/Under by Games in Match', text: '오버언더' },
        { value: 'Total Maps', text: '오버언더' },
      ],
      option4: [
        { value: 'HT', text: '전반' },
        { value: 'FT', text: '종료' },
        { value: 'Finished', text: '종료' },
        { value: 'WO', text: '기권' },
        { value: 'Postp.', text: '연기' },
        { value: 'Postponed', text: '연기' },
        { value: 'Aban.', text: '중단' },
        { value: 'Abandoned', text: '중단' },
        { value: 'Cancl.', text: '취소' },
        { value: 'Cancelled', text: '취소' },
        { value: 'Canceled', text: '취소' },
        { value: 'Susp.', text: '일시중단' },
        { value: 'Suspended', text: '일시중단' },
        { value: 'Int.', text: '일시중단' },
        { value: 'Delayed', text: '지연' },
        { value: 'Awarded', text: '비정상' },
        { value: 'Awarded', text: '비정상' },
        { value: 'Walk over', text: '부전승' },
      ],
      groupByData: [],
      perPage: 10,
      pageOptions: [5, 10, 20],
      currentPage: 1,
      totalRows: 0,
      sortBy: 'createdAt',
      sortDesc: true,
      sortDirection: 'desc',
      filter: null,
      filterOn: [],
      fields1: [
        {
          key: 'no', sortable: false, label: 'No.', thClass: 'd-none',
        },
      ],
      fields2: [
        {
          key: 'seq', sortable: false, label: '폴드', thClass: 'col5',
        },
        // {
        //   key: 'gameId', sortable: false, label: 'GAME ID', thClass: 'col5', tdClass: '',
        // },
        {
          key: 'gameDate', sortable: false, label: '경기일시', thClass: 'col3',
        },
        {
          key: 'sports', sortable: false, label: '구분', thClass: 'col3',
        },
        {
          key: 'gameDate', sortable: false, label: '경기일시', thClass: 'col3',
        },
        {
          key: 'leagueName', sortable: false, label: '리그', thClass: 'col3',
        },
        {
          key: 'homeName', sortable: false, label: '홈팀', thClass: 'col3', tdClass: '',
        },
        {
          key: 'oddsHome', sortable: false, label: '', thClass: 'odds', tdClass: '',
        },
        {
          key: 'handicap', sortable: false, label: '기준', thClass: 'col5', tdClass: '',
        },
        {
          key: 'oddsAway', sortable: false, label: '', thClass: 'odds', tdClass: '',
        },
        {
          key: 'awayName', sortable: false, label: '원정팀', thClass: 'col3', tdClass: '',
        },
        {
          key: 'oddsType', sortable: false, label: '유형', thClass: 'col4',
        },
        // {
        //   key: 'odds', sortable: false, label: '배당', thClass: 'col5',
        // },
        // {
        //   key: 'homeAway', sortable: false, label: '베팅팀', thClass: 'col5',
        // },
        {
          key: 'homeTeamResult', sortable: false, label: '홈', thClass: 'col5',
        },
        {
          key: 'awayTeamResult', sortable: false, label: '원정', thClass: 'col5',
        },
        {
          key: 'status', sortable: false, label: '상태', thClass: 'col5',
        },
        {
          key: 'betResult', sortable: false, label: '적중', thClass: 'col5',
        },
      ],
    }
  },
  created() {
  },
  mounted() {
    this.fetchData()
  },
  beforeDestroy() {
  },
  computed: {
    ...authStore.mapGetters([
      'user',
    ]),
    ...resultStore.mapGetters({
      fetchClientUserBetHistoryDetail: 'fetchClientUserBetHistoryDetail',
    }),
    oddsTypeText() {
      return value => {
        const option = this.option3.find(option => option.value === value)
        return option ? option.text : value
      }
    },
    statusTypeText() {
      return value => {
        const option = this.option4.find(option => option.value === value)
        return option ? option.text : value
      }
    },
  },
  methods: {
    ...resultStore.mapActions({
      $fetchClientUserBetHistoryDetail: FETCH_CLIENT_USER_BET_HISTORY_DETAIL,
      $updateClientUserBetCancel: UPDATE_CLIENT_USER_BET_CANCEL,
    }),
    onKeyupSearchText() {
      this.searchText = document.querySelector('#searchTextInput').value
    },
    async fetchData() {
      // console.log('homeAway :: ', this.homeAway)
      // console.log('gameId :: ', this.record.gameId)

      await this.$fetchClientUserBetHistoryDetail({
        site: this.record.site,
        userid: this.record.requestId,
        // groupId: this.record.uid.split(':')[2],
        groupId: this.record.groupId,
      })
      this.totalRows = this.groupBy(this.fetchClientUserBetHistoryDetail).length
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    groupBy(data) {
      const processedData = _.chain(data)
        .groupBy('groupId')
        .map((groupedData, groupId) => ({
          groupId,
          site: _.get(groupedData, '[0].site'),
          userid: _.get(groupedData, '[0].userid'),
          nickname: _.get(groupedData, '[0].nickname'),
          category: _.get(groupedData, '[0].category'),
          sports: _.get(groupedData, '[0].sports'),
          betCash: _.get(groupedData, '[0].betCash'),
          totalBetResult: _.get(groupedData, '[0].totalBetResult'),
          hitCash: _.get(groupedData, '[0].hitCash'),
          createdAt: _.get(groupedData, '[0].createdAt'),
          totalOdds: _.get(groupedData, '[0].totalOdds'),
          totalHitCash: _.get(groupedData, '[0].totalHitCash'),
          confirmed: _.get(groupedData, '[0].confirmed'),
          games: _.map(groupedData, game => _.pick(game, ['idx', 'gameId', 'gameDate', 'sports', 'leagueName', 'homeName', 'awayName', 'oddsType', 'oddsId', 'odds', 'handicap', 'homeAway', 'seq', 'betCash', 'homeTeamResult', 'awayTeamResult', 'status', 'betResult', 'hitCash', 'totalBetResult', 'totalOdds', 'totalHitCash', 'createdAt', 'oddsHome', 'oddsDraw', 'oddsAway', 'confirmed'])),
        }))
        .value()

      this.groupByData = processedData
      return processedData
    },
    rowDetailsProvider(row) {
      return {
        template: '',
        toggleDetails: () => {
          row.toggleDetails()
        },
        visible: row.detailsShowing,
      }
    },
    formatNumber(value) {
      if (!value) return '0.00'
      return parseFloat(value).toFixed(2)
    },
    // submit
    async submit(target, data) {
      await Swal.fire({
        title: '변경내용을 저장 하시겠습니까?',
        text: data,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        background: 'rgb(3,11,37)',
        customClass: {
          cancelButton: 'btn btn-dark',
          confirmButton: 'btn btn-primary mr-1',
        },
      }).then(async result => {
        if (result.isConfirmed) {
          await this.confirmYes(target, data)
        }
      })
    },
    // confirm Yes
    async confirmYes(target, data) {
      // const mySite = this.userData.site
      // const myId = this.userData.userid
      try {
        if (target === 'updateGameCustomCancel') {
          // console.log(data)
          await this.$updateClientUserBetCancel({
            groupId: data,
            userid: this.userData.userid,
            ip: this.userIp,
          })
          this.$emit('refreshParent')
        }
      } catch {
        console.error()
      } finally {
        Swal.fire({
          title: '저장 완료',
          icon: 'success',
          timer: 5000,
          background: 'rgb(3,11,37)',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        }).then(result => {
          // const args = { site: mySite }
          this.fetchData()
        })
      }
    },
  },
}
</script>

<style scoped>
.odds {
  width: 4rem;
}
.highlight {
  background-color: #ff7c6e !important;
}
</style>

<style scoped lang="scss">
.per-page-selector {
  width: 90px;
}
.highlighted-cell {
  background-color: rgb(156, 189, 71);
  color: #ffffff;
}
#row-details {
  background-color: #00000c !important;
}
</style>
